<template>
  <component :is="currentView" />
</template>

<script>
import HomePage from './views/HomePage.vue'
import QuickSearch from './views/QuickSearch.vue'
// import CollegeSearch from './views/CollegeSearch.vue'
import ContinueAuth from './views/ContinueAuth.vue'
import NotFound from './views/NotFound.vue'

const routes = {
  '/': HomePage,
  // '/college-search': CollegeSearch,
  '/continue_auth': ContinueAuth,
  '/quick-search': QuickSearch,
}

export default {
  name: "AppRouter",
  data() {
    return {
      currentPath: window.location.pathname,
    }
  },
  computed: {
    currentView() {
      return routes[this.currentPath || '/'] || NotFound
    }
  },
}
</script>
