<template>
  <ProgressSpinner />
</template>

<script>

export default {
  beforeCreate() {
    window.location.replace('/quick-search');
  }
};
</script>
