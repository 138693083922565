<template>
  <div class="max-w-12xl mx-auto text-left catalog-search-wrapper">
    <CatalogViewer :catalog="selectedCatalog" :collegeName="selectedCollegeName">
      <template v-slot:header>
        <header class="mb-6 bg-white pt-2 flex flex-row">
          <div class="flex-auto">
            <h1 class="text-3xl font-extrabold mb-1">QuickSearch</h1>
            <div class="text-sm">
              Search and view college catalogs.
            </div>
            <!-- <a :href="urls.collegeSearch" class="text-xs underline text-blue-800 hover:text-blue-500">
              Jump to College Search
            </a> -->
          </div>
          <div class="flex-none">
            <UserMenu v-if="showAuthGate" />
          </div>
        </header>
      </template>
      <template #sidebar>
        <form>
          <div v-if="!isSingleCatalogPage" class="field-group mb-4">
            <label for="states" class="block mb-2 text-sm font-medium text-gray-900">Select a
              state:</label>
            <select v-model="selectedState" @change="onStateChange" id="states"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
              <option value="">Select a state</option>
              <option v-for="state in states" :value="state.code" :key="state.code">{{ state.name }}</option>
            </select>
          </div>

          <div v-if="!isSingleCatalogPage && colleges && colleges.length" class="field-group mb-4">
            <label for="colleges" class="block mb-2 text-sm font-medium text-gray-900">Select a
              college:</label>
            <select v-model="selectedCollege" @change="onCollegeChange" id="colleges"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
              <option value="">Select a college</option>
              <option v-for="college in colleges" :value="college.id" :key="college.id">{{ college.institutionName
                }}</option>
            </select>
          </div>
          <div v-if="!isSingleCatalogPage && selectedState && !colleges" class="field-group">
            <p>No colleges found.</p>
          </div>

          <div v-if="!isSingleCatalogPage && catalogs && catalogs.length" class="field-group">
            <label for="catalogs" class="block mb-2 text-sm font-medium text-gray-900">Select a
              catalog:</label>
            <select v-if="!isSingleCatalogPage && catalogs && catalogs.length" v-model="selectedCatalog" id="catalogs"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4">
              <option selected value="">Select a catalog</option>
              <option v-for="catalog in catalogs" :value="catalog" :key="catalog.id">{{ catalog.edition }}
              </option>
            </select>

            <!-- Web Catalog Link -->
            <a v-if="selectedCatalogFormat === 'Web'" :href="selectedCatalogUrl" target="_blank"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center">
              View this Web Catalog &nbsp;
              <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9" />
              </svg>
            </a>
          </div>

        </form>
      </template>
      <template #footer>
        <footer class="bg-white py-4 px-6 border-t-1 border-solid border-indigo-500 flex flex-col md:flex-row">
          <DegreeDataLogo class="mb-3" />
          <div class="flex-auto flex justify-between flex-row md:flex-col md:text-right text-sm">
            <span>
              &copy; 2023 DegreeData, LLC
            </span>
            <a href="https://degreedata.com/privacy-policy/" class="underline text-right">
              Privacy Policy
            </a>
          </div>
        </footer>
      </template>
    </CatalogViewer>

    <div class="max-w-2xl mx-auto"></div>
  </div>
</template>

<script>
import states from '../data/states';
const shouldAuth = process.env.VUE_APP_SHOULD_AUTH;
export default {

  data() {
    return {
      showAuthGate: shouldAuth,
      urls: {
        collegeSearch: '/college-search'
      },
      selectedCollegeName: '',
      selectedCatalogYear: '',
      isSingleCatalogPage: false,
      selectedState: '',
      selectedCollege: '',
      colleges: [],
      catalogs: [],
      selectedCatalog: null,
      states,
    };
  },
  computed: {
    selectedCatalogFormat() {
      return this.selectedCatalog?.format || '';
    },
    selectedCatalogUrl() {
      return this.selectedCatalogFormat === 'PDF'
        ? `${process.env.VUE_APP_S3_BASE_URL}/${this.selectedCatalog?.s3Id}`
        : this.selectedCatalog?.catalogUrl
        || '';
    },
  },
  beforeMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const catalogId = searchParams.get('catalogId');

    if (catalogId) {
      this.fetchCatalog(catalogId).then((catalog) => {
        if (catalog) {
          this.isSingleCatalogPage = true;
          this.selectedCatalog = catalog;
        }
      })
    }
  },
  methods: {
    async fetchCatalog(catalogId) {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/catalogs?id=${catalogId}`);
        const data = await response.json();

        return data;
      } catch (e) {
        return null;
      }
    },

    async onStateChange() {
      this.colleges = [];
      this.selectedCollege = '';

      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/institutions/state?id=${this.selectedState}`);
        let data = await response.json();

        // Sorting colleges alphabetically by institutionName
        data.sort((a, b) => a.institutionName.localeCompare(b.institutionName));

        this.colleges = data;
      } catch (error) {
        console.error('Error fetching colleges:', error);
      }
    },

    async onCollegeChange() {
      if (this.selectedCollege) {
        const selectedCollegeObj = this.colleges.find(college => college.id === this.selectedCollege);
        this.selectedCollegeName = selectedCollegeObj ? selectedCollegeObj.institutionName : '';

        try {
          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/catalogs/institution?id=${this.selectedCollege}`);
          let data = await response.json();

          // Sorting catalogs alphabetically by edition
          data.sort((a, b) => a.edition.localeCompare(b.edition));

          this.catalogs = data;

          this.selectedCatalog = null;
        } catch (error) {
          console.error('Error fetching catalogs:', error);
        }
      } else {
        this.catalogs = [];
      }
    },
  },


  watch: {
    selectedCollege(newVal) {
      if (newVal) {
        this.onCollegeChange();
      }
    },
  },
};
</script>

<style>
.catalog-search-wrapper {
  height: calc(100vh);
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
