<template>
  <header class="bg-white flex flex-row justify-between flex-shrink m-6 3xl:mx-0">
    <div class="text-left">
      <h1 class="text-3xl font-extrabold mb-1">College Search</h1>
      <p class="text-sm">
        Research academic policies, programs and courses
      </p>
      <a :href="urls.catalogViewer" class="text-xs text-primary-800 hover:text-primary-500 underline">Jump
        to QuickSearch</a>
    </div>

    <div class="ml-auto">
      <DegreeDataLogo class="flex justify-start items-center" />

      <div v-if="showUserMenu" class="flex-grow max-w-40 text-right">
        <UserMenu />
      </div>
    </div>

  </header>
</template>

<script>
export default {
  name: "PageHeader",
  data() {
    const shouldAuth = process.env.VUE_APP_SHOULD_AUTH;
    return {
      showUserMenu: shouldAuth,
      urls: {
        catalogViewer: '/quick-search'
      },
    };
  }
};
</script>