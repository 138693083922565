<template>
  <div class="h-full w-full relative min-h-screen min-w-screen">
    <AppRouter />
    <AccessGate v-if="showAuthGate && !isAuthenticated && pathname !== '/continue_auth'" class="absolute" />
  </div>
</template>

<script>

const shouldAuth = process.env.VUE_APP_SHOULD_AUTH;
export default {
  data() {
    return {
      pathname: window.location.pathname,
      showAuthGate: shouldAuth,
      isAuthenticated: this.$auth0.isAuthenticated,
    }
  }
};
</script>