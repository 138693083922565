<template>
  <div class="grid grid-cols-12 gap-4 md:h-full">
    <div class="flex flex-col justify-between col-span-12 md:col-span-6 lg:col-span-3">
      <div class="bg-white sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6 sm:py-3">
          <slot name="header"></slot>
          <div class="flex flex-col" v-if="!showAuthGate || isAuthenticated">
            <slot name="sidebar"></slot>
            <!-- Search UI -->
            <form @submit.prevent="executeSearch" v-if="selectedCatalogFormat === 'PDF'">
              <div class="search-ui mb-2">
                <label for="search" class="block mb-2 text-sm font-medium text-gray-900">Search this
                  catalog:</label>
                <input id="search" v-model="searchTerm" type="text" placeholder="Search in PDF"
                  class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">

                <button @click="executeSearch" type="submit"
                  class="mr-2 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg px-3 py-2 text-xs font-medium text-center">
                  <template v-if="searchStatus === 'IN_PROGRESS'">
                    <svg class="animate-spin inline -ml-1 mr-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                      fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                      </circle>
                      <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                      </path>
                    </svg>
                  </template>
                  Search
                </button>

                <span v-if="selectedCatalogFormat === 'PDF' && searchPerformed">
                  <button @click="searchPrevious" type="button"
                    class="mr-2 text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg px-3 py-2 text-xs font-medium text-center">Previous</button>

                  <button @click="searchNext" type="button"
                    class="mr-2 text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg px-3 py-2 text-xs font-medium text-center">Next</button>

                  <button @click="searchClear" type="button"
                    class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg px-3 py-2 text-xs font-medium text-center">Clear</button>
                </span>

              </div>
            </form>

            <!-- Search Results -->
            <div class="search-results" v-if="selectedCatalogFormat === 'PDF' && searchPerformed">
              <p class="block mb-2 text-sm font-medium text-gray-900" v-if="currentResult">Current
                Page: {{ currentResult.pageNumber }}</p>
              <p class="block mb-2 text-sm font-medium text-gray-900">Total Results: {{ totalResults
                }}</p>
              <!-- Add navigation for next and previous results if needed -->
            </div>

          </div>
        </div>
      </div>

      <slot name="footer"></slot>
    </div>

    <div class="course-details relative h-full col-span-12 md:col-span-6 lg:col-span-9">
      <div class="w-full mt-0 h-full">
        <div class="bg-white h-full">
          <div class="h-full">
            <!-- PDF Viewer or iFrame based on the selected catalog -->
            <div id="adobe-dc-view" class="h-full" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export const CatalogViewerDialogProps = {
  header: "QuickSearch",
  draggable: false,
  class: "quick-search-dialog",
  modal: true,
};

export const CatalogViewerDialogState = {
};
export const CatalogViewerDialogData = {
};
export default {
  /* global AdobeDC */
  name: 'CatalogViewer',

  inject: ['dialogRef'],
  props: {
    catalog: Object,
    collegeName: String
  },

  data() {
    const shouldAuth = process.env.VUE_APP_SHOULD_AUTH;
    return {
      showAuthGate: shouldAuth,
      isAuthenticated: this.$auth0.isAuthenticated,
      urls: {
        collegeSearch: '/college-search'
      },
      selectedPdf: null,
      adobeViewer: null,
      searchTerm: '',
      searchStatus: null,
      isSingleCatalogPage: true,
      searchResults: [],
      searchPerformed: false,
      currentResult: null,
      totalResults: 0,
    };
  },
  computed: {
    selectedCatalog() {
      return this.catalog || this.dialogRef?.data?.catalog;
    },
    selectedCollegeName() {
      return this.collegeName || this.dialogRef?.data?.collegeName;
    },
    hasCatalog() { return !!this.selectedCatalog },
    selectedCatalogYear() {
      return this.selectedCatalog?.edition || '';
    },
    selectedCatalogFormat() {
      return this.selectedCatalog?.format || '';
    },
    selectedCatalogUrl() {
      return this.selectedCatalogFormat === 'PDF'
        ? `${process.env.VUE_APP_S3_BASE_URL}/${this.selectedCatalog?.s3Id}`
        : this.selectedCatalog?.catalogUrl
        || '';
    },
  },
  watch: {
    hasCatalog: {
      immediate: true,
      handler(hasCatalog) {
        if (!hasCatalog) return;

        this.$nextTick(async () => {
          try {
            if (this.selectedCatalogFormat === 'PDF') {
              this.loadPdf(this.selectedCatalogUrl);
            }
          } catch (error) {
            console.error('Error loading PDF:', error);
          }
        });

      }
    }

  },

  methods: {

    loadPdf(url) {
      this.$nextTick(() => {
        // console.log(url);
        this.selectedPdf = url;
        const viewerConfig = {
          // embedMode: "IN_LINE",
          defaultViewMode: "FIT_WIDTH",
          showAnnotationTools: false,
          showDownloadPDF: false,
          showPrintPDF: false,
          enableSearchAPIs: true
        };

        const adobeDCView = new AdobeDC.View({
          clientId: process.env.VUE_APP_ADOBE_CLIENT_ID,
          divId: "adobe-dc-view",
        });

        adobeDCView.previewFile({
          content: {
            location: { url: this.selectedPdf },
          },
          metaData: {
            fileName: this.selectedCollegeName + ' - ' + this.selectedCatalogYear + '.pdf',
          },
        }, viewerConfig).then(adobeViewer => {
          this.adobeViewer = adobeViewer;
        });
      });
    },

    executeSearch() {
      if (!this.adobeViewer || this.searchTerm.length === 0) {
        return;
      }

      this.adobeViewer.getAPIs().then(apis => {
        apis.search(this.searchTerm).then(searchObject => {
          this.searchObject = searchObject;
          searchObject.onResultsUpdate(this.onResultsUpdate).catch(this.onSearchFail);
          this.searchPerformed = true;
        }).catch(this.onSearchFail);
      });
    },

    // Method to handle search results update
    onResultsUpdate(result) {
      console.log(result);

      this.searchStatus = result.status;
      this.currentResult = result.currentResult;
      this.totalResults = result.totalResults;
    },

    // Method to handle search failures
    onSearchFail(error) {
      console.error('Search failed:', error);
    },

    // Additional methods for navigating search results
    searchNext() {
      if (this.searchObject) {
        this.searchObject.next();
      }
    },

    searchPrevious() {
      if (this.searchObject) {
        this.searchObject.previous();
      }
    },

    searchClear() {
      if (this.searchObject) {
        this.searchObject.clear();
      }
      this.searchTerm = '';
      this.searchPerformed = false;
      this.searchStatus = null;
    },

  },
};
</script>

<style></style>
