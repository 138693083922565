<template>
  <template v-if="isAuthenticated">
    <Button size="small" type="button" icon="pi pi-bars" aria-haspopup aria-label="Main Menu" aria-controls="main_menu"
      @click="onClick">
      <template #icon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="white" class="w-5">
          <!-- !Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
          <path
            d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
        </svg>
      </template>
    </Button>
    <Menu ref="menu" id="main_menu" :model="items" popup />
  </template>
</template>
<script>
export default {
  name: "UserMenu",
  computed: {
    app_metadata() {
      const user = this.user;
      const app_metadata = user && user[process.env.VUE_APP_AUTH0_APP_META_KEY];
      return app_metadata;
    },
    items() {
      const { billing_portal_url } = this.app_metadata || {};

      return ([
        !!billing_portal_url && {
          label: `Manage subscription`,
          url: billing_portal_url
        },
        {
          label: `Logout`,
          command: this.logout
        }
      ]).filter(v => v);
    },
    onClick() {
      return this.toggle;
    },
  },
  data() {
    return {
      urls: {
        catalogViewer: '/quick-search',
      },
      isAuthenticated: this.$auth0.isAuthenticated,
      user: this.$auth0.user,
    };
  },
  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    logout() {
      this.$auth0.logout({ logoutParams: { returnTo: window.location.href } });
    },
  },
};
</script>
