<template>
  <div :class="`flex justify-center items-center text-center flex-1 absolute inset-0`">
    <div class="bg-surface-0 blur-lg opacity-75 absolute inset-0" />
    <div class="bg-surface-0 flex flex-col justify-center items-center text-center z-10 p-5 rounded-lg">
      <template v-if="searchParams.get('close') === 'true'">
        <p class="text-lg">
          Active subscription required to access DegreeData
        </p>

        <a href="/" class="font-bold mt-5 relative items-center inline-flex text-center align-bottom justify-center leading-[normal] px-4 py-3 rounded-md text-white bg-primary-500 border border-primary-500 focus:outline-none focus:outline-offset-0 focus:ring hover:bg-primary-600 hover:border-primary-600 focus:ring-primary-400/50 transition duration-200 ease-in-out cursor-pointer overflow-hidden select-none">
          Return Home
        </a>
      </template>
      <ProgressSpinner v-else />
    </div>
  </div>
</template>
<script>
import { jwtDecode } from "jwt-decode";
import { STORAGE_KEYS } from '../components/AccessGate.vue';

export default {
  name: "ContinueAuth",
  props: {
    class: String,
  },
  created() {
    const stateParam = this.searchParams.get('state');
    const closeParam = this.searchParams.get('close');
    const sessionTokenParam = this.searchParams.get('session_token');

    if (closeParam === 'true') {
      window.close();
    } else if (stateParam && sessionTokenParam) {
      localStorage.setItem(STORAGE_KEYS.STATE, stateParam)
      localStorage.setItem(STORAGE_KEYS.TOKEN, sessionTokenParam)

      const decodedToken = jwtDecode(sessionTokenParam);

      if (decodedToken.customer_type === 'new') {
        this.redirectTo(decodedToken.checkout_url);
      } else {
        this.redirectTo(decodedToken.billing_portal_url);
      }
    } else {
      const state = localStorage.getItem(STORAGE_KEYS.STATE);
      const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
      localStorage.removeItem(STORAGE_KEYS.STATE);
      localStorage.removeItem(STORAGE_KEYS.TOKEN);

      if (token && state) {
        const decodedToken = jwtDecode(token);

        this.redirectTo((`${decodedToken.continue_uri}?state=${state}`));
      }
    }

  },
  data() {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      popupError: [],
      searchParams,
      pathname: window.location.pathname,
      isLoading: this.$auth0.isLoading,
      isAuthenticated: this.$auth0.isAuthenticated,
    };
  },
  methods: {
    async login() {
    },
    redirectTo(path) {
      window.location.replace(path);
    }
  },
};
</script>

<style></style>