<template>
  <DataTable tableStyle="min-width: 50rem" table-class="table-colleges" :filterDisplay="filterDisplay"
    v-model:filters="filters" :value="shownColleges" :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]"
    sortField="institutionName" sortOrder="1" paginator>
    <Column :show-clear-button="false" :show-filter-menu="false" :show-add-button="false" field="institutionName"
      sortable header="Institution" filter-header-class="w-full" dataType="text" style="width: 25ch">
      <template #filter="{ filterModel, filterCallback }">
        <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter w-full"
          placeholder="Filter by institution" />
      </template>
      <template #body="slotProps">
        <span class="text-sm">{{ slotProps.data.institutionName }}</span>
      </template>
    </Column>
    <Column field="state" sortable :show-clear-button="false" :show-filter-menu="false" header="State" dataType="text"
      style="width:12ch;">
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown showClear v-model="filterModel.value" @change="filterCallback()" :options="states" optionLabel="name"
          optionValue="code" placeholder="State" class="w-full md:w-14rem" />
      </template>
      <template #body="slotProps">
        <span class="text-sm">{{ states.find((el) => el.code == slotProps.data.state)?.name }}</span>
      </template>
    </Column>
    <Column field="level" sortable :show-clear-button="false" :show-filter-menu="false" header="Level"
      style="width:12ch;">
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown showClear v-model="filterModel.value" @change="filterCallback()" :options="levels" optionLabel="name"
          optionValue="code" placeholder="Level" class="w-full md:w-14rem" />
      </template>
      <template #body="slotProps">
        <span class="text-sm">{{ levels.find((el) => el.code == slotProps.data.level)?.name }}</span>
      </template>
    </Column>
    <Column field="control" :show-clear-button="false" :show-filter-menu="false" style="width: 13ch;" sortable
      header="Control">
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown showClear v-model="filterModel.value" @change="filterCallback()" :options="control" optionLabel="name"
          optionValue="code" placeholder="Control" class="w-full md:w-14rem" />
      </template>
      <template #body="slotProps">
        <span class="text-sm">{{ control?.find((el) => el.code == slotProps.data.control)?.name }}</span>
      </template>
    </Column>
    <Column field="academicAssociation" :show-clear-button="false" :show-filter-menu="false" sortable
      header="Affinity Group" style="width:18ch;">
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown showClear v-model="filterModel.value" @change="filterCallback()" :options="affinityGroup"
          optionLabel="name" optionValue="code" placeholder="Affinity Group" class="w-full md:w-14rem" />
      </template>
      <template #body="slotProps">
        <span class="text-sm">{{ affinityGroup?.find((el) => el.code ==
    slotProps.data.academicAssociation)?.name }}</span>
      </template>
    </Column>
    <Column header="Record">
      <template #body="bodySlotProps">
        <Dropdown :options="catalogs[bodySlotProps.data.id]" optionLabel="edition"
          @click="() => this.onClickCatalogs(bodySlotProps.data.id)" optionValue="id" placeholder="Choose the catalog"
          variant="link-list" class="w-full md:w-14rem">
          <template #option="slotProps">
            <button class="py-3 px-5 block" :onClick="() => onClickCatalog(slotProps.option, bodySlotProps.data)">
              {{ slotProps.option.edition }}
            </button>
          </template>
        </Dropdown>
      </template>
    </Column>
    <template #empty>
      <p class="text-left text-sm font-bold py-6">
        <template v-if="hasFilters">
          No results found
        </template>
      </p>
    </template>
  </DataTable>
</template>

<script>
import tailwindConfig from '@/../tailwind.config';
import affinityGroups from '../data/affinity-groups';
import controls from '../data/controls';
import levels from '../data/levels';
import states from '../data/states';
import CatalogViewer, { CatalogViewerDialogProps, CatalogViewerDialogState } from './CatalogViewer.vue';
import { useWindowSize } from '@vueuse/core'
const screensList = Object.entries(tailwindConfig.theme.screens);

const { width } = useWindowSize();

const collegeCacheKey = 'college-search--colleges-cache';
const catalogCacheKey = (institutionId) => `college-search--catalog-cache--${institutionId}`;
export default {
  name: "CollegeTable",

  created() {
    this?.onInit();
  },

  data() {
    return {
      width,
      urls: {
        catalogViewer: '/quick-search'
      },
      catalogs: {},
      colleges: [],
      filters: {
        institutionName: { value: null, matchMode: 'contains' },
        state: { value: null, matchMode: 'equals' },
        level: { value: null, matchMode: 'equals' },
        control: { value: null, matchMode: 'equals' },
        academicAssociation: { value: null, matchMode: 'equals' },
      },
      affinityGroup: affinityGroups,
      control: controls,
      levels,
      states,
    };
  },
  computed: {
    breakpoint() {
      let bp = screensList[0][0];
      for (const [key, value] of screensList) {
        if (this.width > parseInt(value)) {
          bp = key;
        } else {
          break;
        }
      }

      return bp;
    },
    filterDisplay() {
      return this.breakpoint === 'sm' ? 'menu' : 'row';
    },
    hasFilters() {
      for (const [, state] of Object.entries(this.filters)) {
        if (state.value) return true;
      }
      return false;
    },
    shownColleges() {
      return (this.hasFilters) ? this.colleges : [];
    }
  },
  watch: {
  },
  methods: {
    async getCatalogs(institutionId) {
      try {
        const cachedCatalogs = JSON.parse(localStorage.getItem(catalogCacheKey(institutionId)) ?? '[]');
        console.log('cached catalog.', cachedCatalogs)

        if (cachedCatalogs && Array.isArray(cachedCatalogs) && cachedCatalogs.length) {
          return cachedCatalogs;
        }
      } catch (error) {
        console.error('Error fetching cached colleges:', error);
      }

      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/catalogs/institution?id=${institutionId}`);
        const json = await response.json();
        const data = json.sort((a, b) => parseInt(b.edition) - parseInt(a.edition));

        localStorage.setItem(catalogCacheKey(institutionId), JSON.stringify(data));

        console.log('not cached.', data)
        return data;
      } catch (error) {
        console.error('Error fetching catalog:', error);

        return [];
      }
    },
    async getColleges() {
      try {
        const cachedColleges = JSON.parse(localStorage.getItem(collegeCacheKey) ?? '[]');

        if (cachedColleges && Array.isArray(cachedColleges) && cachedColleges.length) {
          return cachedColleges;
        }
      } catch (error) {
        console.error('Error fetching cached colleges:', error);
      }

      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/institutions/all`);
        let data = await response.json();

        localStorage.setItem(collegeCacheKey, JSON.stringify(data));

        console.log('not cached.', data)
        return data;
      } catch (error) {
        console.error('Error fetching colleges:', error);

        return [];
      }
    },

    async onClickCatalogs(institutionId) {
      const catalogs = await this.getCatalogs(institutionId);
      this.catalogs[institutionId] = catalogs;
    },

    async onClickCatalog(option, institution) {
      this.$dialog.open(
        CatalogViewer,
        {
          props: CatalogViewerDialogProps,
          state: CatalogViewerDialogState,
          data: {
            catalog: option,
            collegeName: institution?.institutionName
          }
        }
      );
    },

    async onInit() {
      const colleges = await this.getColleges();
      this.colleges = colleges;
    },
  },
};
</script>