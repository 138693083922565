<template>
  <a href="https://degreedata.com" target="_blank" :class="this.class">
    <img src="https://degreedata.com/wp-content/uploads/2020/12/degreedata_logo.png" alt="DegreeData Logo"
      :class="imgClass" />
  </a>
</template>
<script>

export default {
  name: 'DegreeDataLogo',
  props: {
    class: {
      type: String,
      default: '',
    },
    imgClass: {
      type: String,
      default: 'w-40 object-contain flex-none',
    }
  }
}
</script>