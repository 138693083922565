<template>
  <div :class="`flex justify-center items-center text-center flex-1 ${this.class ?? ''} inset-0`">
    <div class="bg-surface-0 blur-lg opacity-75 absolute inset-0" />
    <div class="shadow-2xl shadow-[#333] bg-white max-w-96 w-full rounded-lg flex flex-col justify-center items-center text-center z-10 px-10 pt-12 pb-14 rounded-lg">
      <ProgressSpinner v-if="isLoading" />
      <template v-else>
        <p class="font-bold text-lg color-purple">
          Welcome to DegreeData
        </p>
        <template v-if="popupError?.length > 0">
          <p class="mb-6 text-error">
            <span v-for="(popup, popupIndex) in popupError" :key="popup">
              <br v-if="popupIndex" />
              {{ popup }}
            </span>
          </p>
        </template>
        <h1 class="font-bold text-4xl color-purple mt-3">QuickSearch</h1>
        <p class="text-lg mb-6 mt-3">
          Search US academic catalogs for courses, programs and policies.
        </p>
        <Button label="Start your free trial or Login" @click="signup" class="font-normal rounded-[10px] px-4 py-1 h-12 w-full text-base" />

        <DegreeDataLogo imgClass="max-h-[50px] mt-10" />

      </template>
    </div>
  </div>
</template>
<script>
export const STORAGE_KEYS = {
  STATE: 'ACCESS_STATE',
  TOKEN: 'ACCESS_TOKEN'
};

export default {
  name: "AccessGate",
  props: {
    class: String,
  },
  created() {
    localStorage.removeItem(STORAGE_KEYS.STATE);
    localStorage.removeItem(STORAGE_KEYS.TOKEN);
  },
  data() {
    return {
      popupError: [],
      isLoading: this.$auth0.isLoading,
    };
  },
  methods: {
    async login() {
      this.popupError = [];
      try {
        await this.$auth0.loginWithPopup();
      } catch (e) {
        this.handleAuthError(e);
      }
    },
    async signup() {
      this.popupError = [];
      try {
        await this.$auth0.loginWithPopup({
          authorizationParams: {
            screen_hint: 'signup'
          }
        });
      } catch (e) {
        this.handleAuthError(e);
      }
    },
    handleAuthError(e) {
      if (e.message == 'Popup closed') {
        this.popupError = [
          'Please try your login or signup again',
        ]
      } else {
        this.popupError = [
          'Error during authentication:',
          e.message,
          'Please disable popup blockers or try again'
        ];
      }
    }
  },
};
</script>