import { createApp } from 'vue';
import { createAuth0 } from '@auth0/auth0-vue';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import DialogService from 'primevue/dialogservice';
import Dropdown from 'primevue/dropdown';
import DynamicDialog from 'primevue/dynamicdialog';
import InputText from 'primevue/inputtext';
import Menu from 'primevue/menu';
import PrimeVue from 'primevue/config';
import ProgressSpinner from 'primevue/progressspinner';

import App from './App.vue';
import AppRouter from './AppRouter.vue';

import AccessGate from './components/AccessGate.vue';
import CatalogViewer from './components/CatalogViewer.vue';
import CollegeTable from './components/CollegeTable.vue';
import DegreeDataLogo from './components/DegreeDataLogo.vue';
import PageHeader from './components/PageHeader.vue';
import UserMenu from './components/UserMenu.vue';

import presetTheme from './tailwind-presets/college-search';

import 'primeicons/primeicons.css'
import './index.css'

const app = createApp(App);

app
  .use(
    createAuth0({
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin
      }
    })
  )
  .use(PrimeVue, {
    unstyled: true,
    pt: presetTheme,
  })
  .use(DialogService)
  .component(AccessGate.name, AccessGate)
  .component(AppRouter.name, AppRouter)
  .component(Button.name, Button)
  .component(CatalogViewer.name, CatalogViewer)
  .component(CollegeTable.name, CollegeTable)
  .component(Column.name, Column)
  .component(DataTable.name, DataTable)
  .component(DegreeDataLogo.name, DegreeDataLogo)
  .component(Dropdown.name, Dropdown)
  .component(DynamicDialog.name, DynamicDialog)
  .component(InputText.name, InputText)
  .component(Menu.name, Menu)
  .component(PageHeader.name, PageHeader)
  .component(ProgressSpinner.name, ProgressSpinner)
  .component(UserMenu.name, UserMenu)
  .mount('#app');
